import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getnsfwWordContent = createAsyncThunk(
  "/nsfwwords/getnsfwWordContent",
  async () => {
    const response = await axios.get("/api/easyart/get-nsfw", {
      headers: {
        // Origin: "http://localhost:3000", //Todo:Fix before deploy
      },
    });
    return response.data;
  }
);

export const addNewWordAsync = createAsyncThunk(
  "nsfwwords/addNewWordAsync",
  async (newWord) => {
    // Make the POST request using Axios
    const response = await axios.post("/api/easyart/add-nsfw", newWord, {
      headers: {
        "Content-Type": "application/json", // Set appropriate headers for FormData
      },
    });
    return response.data; // You can modify this as needed based on your API response
  }
);

export const deleteWordAsync = createAsyncThunk(
  "nsfwwords/deleteWordAsync",
  async (obj) => {
    try {
      await axios.post("/api/easyart/delete-nsfw", {
        id: obj._id,
      })
      return obj;
    } catch (error) {
      throw error;
    }
  }
);

export const editWordAsync = createAsyncThunk(
  "nsfwwords/editWordAsync",
  async (obj) => {
    const newWord = {
      id:obj._id,
      word:obj.word,
      lang:obj.lang,
    }
    try {
      const response = await axios.post(
        "/api/easyart/edit-nsfw",
        newWord,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const nsfwWordsSlice = createSlice({
  name: "nsfwwords",
  initialState: {
    isLoading: false,
    words: [],
  },
  reducers: {},
  extraReducers: {
    [getnsfwWordContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getnsfwWordContent.fulfilled]: (state, action) => {
      state.words = action.payload.data;
      state.isLoading = false;
    },
    [getnsfwWordContent.rejected]: (state) => {
      state.isLoading = false;
    },
    [addNewWordAsync.fulfilled]: (state, action) => {
      // console.log("DUCK", "add New Word", action.payload);
      state.words = [...state.words, action.payload.data];
      state.isLoading = false;
    },
    [deleteWordAsync.fulfilled]: (state, action) => {
      const { index } = action.payload;
      state.words.splice(index, 1);
    },
    [editWordAsync.fulfilled]: (state, action) => {
      const {data} = action.payload;
      const index = state.words.findIndex(
        (item) => item._id === data._id
      );

      if (index !== -1) {
        state.words[index] = data;
      }
    },
  },
});

export default nsfwWordsSlice.reducer;
