import { configureStore } from "@reduxjs/toolkit";
import headerSlice from "../features/common/headerSlice";
import modalSlice from "../features/common/modalSlice";
import rightDrawerSlice from "../features/common/rightDrawerSlice";
import leadsSlice from "../features/leads/leadSlice";
import inspirationSlice from "../features/inspitation/inspirationSlice";
import promptSlice from "../features/prompts/promptSlice";
import nsfwWordsSlice from "../features/nsfwWords/nsfwWordsSlice";
import styleSlice from "../features/styles/styleSlice";
import imageGenerationSettingsSlice from "../features/imageGenerationSettings/imageGenerationSettingsSlice";
import backgroundImagesSlice from "../features/backgroundImages/backgroundImagesSlice";
import avatarSlice from "../features/avatar/avatarSlice";
import avatarResultsSlice from "../features/avatarResults/avatarResultsSlice";

const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  lead: leadsSlice,
  inspiration: inspirationSlice,
  avatar: avatarSlice,
  avatarResults: avatarResultsSlice,
  nsfwwords: nsfwWordsSlice,
  style: styleSlice,
  prompt: promptSlice,
  imageGenerationSettings: imageGenerationSettingsSlice,
  backgroundImages: backgroundImagesSlice
};

export default configureStore({
  reducer: combinedReducer,
});
