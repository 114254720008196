import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getImageGenerationSettingsContent = createAsyncThunk(
  "/imageGenerationSettings/getImageGenerationSettingsContent",
  async () => {
    const response = await axios.get("/api/easyart/get-settings", {
      headers: {
        // Origin: "http://localhost:3000", //Todo:Fix before deploy
      },
    });
    return response.data;
  }
);

export const addNewImageGenerationSettingAsync = createAsyncThunk(
  "imageGenerationSettings/addNewImageGenerationSettingAsync",
  async (newWord) => {
    // Make the POST request using Axios
    const response = await axios.post("/api/easyart/add-settings", newWord, {
      headers: {
        "Content-Type": "application/json", // Set appropriate headers for FormData
      },
    });
    return response.data; // You can modify this as needed based on your API response
  }
);

// export const deleteWordAsync = createAsyncThunk(
//   "imageGenerationSettings/deleteWordAsync",
//   async (obj) => {
//     try {
//       await axios.post("/api/easyart/delete-nsfw", {
//         id: obj._id,
//       })
//       return obj;
//     } catch (error) {
//       throw error;
//     }
//   }
// );

export const editImageGenerationSettingAsync = createAsyncThunk(
  "imageGenerationSettings/editImageGenerationSettingAsync",
  async (obj) => {
    const newSetting = {
      id:obj._id,
      setting_name:obj.setting_name,
      setting_value:obj.setting_value,
    }
    try {
      const response = await axios.post(
        "/api/easyart/edit-settings",
        newSetting,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log('edit ressss',response)
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const imageGenerationSettingsSlice = createSlice({
  name: "imageGenerationSettings",
  initialState: {
    isLoading: false,
    settings: [],
  },
  reducers: {},
  extraReducers: {
    [getImageGenerationSettingsContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getImageGenerationSettingsContent.fulfilled]: (state, action) => {
      state.settings = action.payload.data;
      state.isLoading = false;
    },
    [getImageGenerationSettingsContent.rejected]: (state) => {
      state.isLoading = false;
    },
    [addNewImageGenerationSettingAsync.fulfilled]: (state, action) => {
      state.settings = [...state.settings, action.payload.data];
      state.isLoading = false;
    },
    // [deleteWordAsync.fulfilled]: (state, action) => {
    //   const { index } = action.payload;
    //   state.settings.splice(index, 1);
    // },
    [editImageGenerationSettingAsync.fulfilled]: (state, action) => {
      const {data} = action.payload;
      const index = state.settings.findIndex(
        (item) => item._id === data._id
      );

      if (index !== -1) {
        state.settings[index] = data;
      }
    },
  },
});

export default imageGenerationSettingsSlice.reducer;
