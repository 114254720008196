import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getInspirationContent = createAsyncThunk(
  "/inspirations/content",
  async () => {
    const response = await axios.get("/api/easyart/get-inspirations", {
      headers: {
        Origin: "http://localhost:3000", //Todo:Fix before deploy
      },
    });
    console.log("DUCK", "get-inspirations", response.data);
    return response.data;
  }
);

export const addNewInspirationAsync = createAsyncThunk(
  "inspirations/addNewInspirationAsync",
  async (newInspirationObj) => {
    console.log("DUCK", "addNewInspirationAsync", newInspirationObj);

    // Create a new FormData instance
    const formData = new FormData();

    // Append the text data to the form data
    formData.append("inspiration_name", newInspirationObj.inspiration_name);
    formData.append("inspiration_prompt", newInspirationObj.inspiration_prompt);
    formData.append("isPriority", newInspirationObj.isPriority);

    // Append the image file to the form data
    formData.append("inspiration_image", newInspirationObj.inspiration_image);

    // Make the POST request using Axios
    const response = await axios.post("/api/easyart/add-inpiration", formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Set appropriate headers for FormData
      },
    });

    return response.data; // You can modify this as needed based on your API response
  }
);

export const deleteInspirationAsync = createAsyncThunk(
  "inspirations/deleteInspirationAsync",
  async (deletedItemId) => {
    try {
      const response = await axios.post("/api/easyart/delete-inpiration", {
        id: deletedItemId,
      });

      return deletedItemId;
    } catch (error) {
      throw error;
    }
  }
);

export const editInspirationAsync = createAsyncThunk(
  "inspirations/editInspirationAsync",
  async (editedInspiration) => {
    const formData = new FormData();

    console.log("DUCK", "editedInspiration", editedInspiration);

    formData.append("id", editedInspiration.inspiration_id);
    formData.append("inspiration_name", editedInspiration.inspiration_name);
    formData.append("inspiration_prompt", editedInspiration.inspiration_prompt);
    formData.append("isPriority", editedInspiration.isPriority);
    formData.append("inspiration_image", editedInspiration.inspiration_image);

    try {
      const response = await axios.post(
        "/api/easyart/edit-inpiration",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const inspirationSlice = createSlice({
  name: "inspirations",
  initialState: {
    isLoading: false,
    inspirations: [],
  },
  reducers: {
    addNewInspiration: (state, action) => {
      let { newInspirationObj } = action.payload;

      //state.inspirations = [...state.inspirations, newInspirationObj];
    },

    deleteInspiration: (state, action) => {
      let { index } = action.payload;
      state.inspirations.splice(index, 1);
    },
  },

  extraReducers: {
    [getInspirationContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getInspirationContent.fulfilled]: (state, action) => {
      state.inspirations = action.payload.data;
      state.isLoading = false;
    },
    [getInspirationContent.rejected]: (state) => {
      state.isLoading = false;
    },
    [addNewInspirationAsync.fulfilled]: (state, action) => {
      console.log("DUCK", "addNewInspirationAsync", action.payload);
      state.inspirations = [...state.inspirations, action.payload.data];

      state.isLoading = false;
    },
    [deleteInspirationAsync.fulfilled]: (state, action) => {
      const { index } = action.payload;
      //state.inspirations.splice(index, 1);
    },
    [editInspirationAsync.fulfilled]: (state, action) => {
      console.log(action.payload.data);
      const editedInspiration = action.payload.data;
      const index = state.inspirations.findIndex(
        (item) => item._id === editedInspiration._id
      );

      if (index !== -1) {
        state.inspirations[index] = editedInspiration;
      }
    },
  },
});

export const { addNewInspiration, deleteInspiration } =
  inspirationSlice.actions;

export default inspirationSlice.reducer;
