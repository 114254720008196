import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getBackgroundImagesContent = createAsyncThunk(
  "/backgroundimages/getBackgroundImagesContent",
  async () => {
    const response = await axios.get("/api/easyart/get-background")
    // console.log("DUCK", "get-backgroundimages", response.data);
    return response.data;
  }
);

export const addNewBackgroundImageAsync = createAsyncThunk(
  "backgroundimages/addNewBackgroundImageAsync",
  async (newImageObj) => {
    console.log("DUCK", "addNewBackgroundImageAsync", newImageObj);

    // Create a new FormData instance
    const formData = new FormData();

    // Append the text data to the form data
    formData.append("background_name", newImageObj.background_name);
    formData.append("background_image", newImageObj.background_image);
    formData.append("background_isActive", newImageObj.background_isActive);

    // Make the POST request using Axios
    const response = await axios.post("/api/easyart/add-background", formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Set appropriate headers for FormData
      },
    });

    return response.data; // You can modify this as needed based on your API response
  }
);

export const deleteBackgroundImageAsync = createAsyncThunk(
  "backgroundimages/deleteBackgroundImageAsync",
  async (deletedItemId) => {
    console.log('deletedItemId', deletedItemId)
    try {
      const response = await axios.post("/api/easyart/delete-background",
        {
          id: deletedItemId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      )
      if (response.status == 200)
        return deletedItemId
    } catch (error) {
      throw error;
    }
  }
);

export const editBackgroundImageAsync = createAsyncThunk(
  "backgroundimages/editBackgroundImageAsync",
  async (editedImage) => {
    const formData = new FormData();

    console.log("DUCK", "editedImage", editedImage);

    formData.append("id", editedImage.background_id);
    formData.append("background_name", editedImage.background_name);
    formData.append("background_image", editedImage.background_image);
    formData.append("background_isActive", editedImage.background_isActive);

    console.log("DUCK", "editedImage form data", formData);

    try {
      const response = await axios.post(
        "/api/easyart/edit-background",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const backgroundImagesSlice = createSlice({
  name: "backgroundimages",
  initialState: {
    isLoading: false,
    backgroundimages: [],
  },
  reducers: {
    addNewBackgroundImage: (state, action) => {
      let { newImageObj } = action.payload;

      //state.backgroundimages = [...state.backgroundimages, newImageObj];
    },

    deleteBackgroundImage: (state, action) => {
      let { index } = action.payload;
      state.backgroundimages.splice(index, 1);
    },
  },

  extraReducers: {
    [getBackgroundImagesContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getBackgroundImagesContent.fulfilled]: (state, action) => {
      state.backgroundimages = action.payload.data;
      state.isLoading = false;
    },
    [getBackgroundImagesContent.rejected]: (state) => {
      state.isLoading = false;
    },
    [addNewBackgroundImageAsync.fulfilled]: (state, action) => {
      // console.log("DUCK", "addNewBackgroundImageAsync", action.payload);
      state.backgroundimages = [...state.backgroundimages, action.payload.data];
      state.isLoading = false;
    },
    [deleteBackgroundImageAsync.fulfilled]: (state, action) => {
      const _id = action.payload;
      state.backgroundimages = state.backgroundimages.filter(item => item?._id != _id)
    },
    [editBackgroundImageAsync.fulfilled]: (state, action) => {
      console.log(action.payload.data);
      const editedImage = action.payload.data;
      const index = state.backgroundimages.findIndex(
        (item) => item._id === editedImage._id
      );

      if (index !== -1) {
        state.backgroundimages[index] = editedImage;
      }
    },
  },
})

export const { addNewBackgroundImage, deleteBackgroundImage } = backgroundImagesSlice.actions

export default backgroundImagesSlice.reducer