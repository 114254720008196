import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAvatarResultsContent = createAsyncThunk(
  "/avatarResults/content",
  async () => {
    const response = await axios.get("/api/easyart/get-user-avatar");
    // console.log("DUCK", "get-avatarResults", response?.data);
    return response?.data;
  }
);

export const avatarResultsSlice = createSlice({
  name: "avatarResults",
  initialState: {
    isLoading: false,
    avatarResults: [],
  },
  reducers: {},

  extraReducers: {
    [getAvatarResultsContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getAvatarResultsContent.fulfilled]: (state, action) => {
      state.avatarResults = action.payload?.data || [];
      //console.log("DUCK", "getAvatarResultsContent", action.payload);
      state.isLoading = false;
    },
    [getAvatarResultsContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});


export default avatarResultsSlice.reducer;
