import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getPromptContent = createAsyncThunk(
  "/prompt/content",
  async () => {
    const response = await axios.get("/api/easyart/get-prompts");
    // console.log("DUCK", "get-prompts", response.data.promptData.data);
    return response.data;
  }
);

export const promptSlice = createSlice({
  name: "prompts",
  initialState: {
    isLoading: false,
    prompts: [],
    numOfRecords: 0,
  },
  reducers: {
    addNewInspiration: (state, action) => {
      let { newInspirationObj } = action.payload;

      //state.prompts = [...state.prompts, newInspirationObj];
    },

    deleteInspiration: (state, action) => {
      let { index } = action.payload;
      state.prompts.splice(index, 1);
    },
  },

  extraReducers: {
    [getPromptContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getPromptContent.fulfilled]: (state, action) => {
      state.prompts = action.payload.promptData.data;
      state.numOfRecords = action.payload.promptData.numberOfRecords;
      //console.log("DUCK", "getPromptContent", action.payload);
      state.isLoading = false;
    },
    [getPromptContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { addNewInspiration, deleteInspiration } = promptSlice.actions;

export default promptSlice.reducer;
