import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getStyleContent = createAsyncThunk(
  "/styles/getStyleContent",
  async () => {
    const response = await axios.get("/api/easyart/get-style-list", {
      headers: {
        // Origin: "http://localhost:3000", //Todo:Fix before deploy
      },
    });
    // console.log('styleee resssss',response)
    return response.data;
  }
);

export const addNewStyleAsync = createAsyncThunk(
  "styles/addNewStyleAsync",
  async (newStyle) => {
    // Make the POST request using Axios
    const response = await axios.post("/api/easyart/add-styles", newStyle, {
      headers: {
        "Content-Type": "multipart/form-data", // Set appropriate headers for FormData
      },
    });
    console.log('addnew style',response.data)
    return response.data; // You can modify this as needed based on your API response
  }
);

export const deleteStyleAsync = createAsyncThunk(
  "styles/deleteStyleAsync",
  async (obj) => {
    try {
      const res=await axios.post("/api/easyart/delete-style", {
        id: obj._id,
      })
      console.log('res',res)
      return obj;
    } catch (error) {
      throw error;
    }
  }
);

export const editStyleAsync = createAsyncThunk(
  "styles/editStyleAsync",
  async (data) => {
    try {
      const response = await axios.post(
        "/api/easyart/edit-styles",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set appropriate headers for FormData
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const styleSlice = createSlice({
  name: "style",
  initialState: {
    isLoading: false,
    styles: [],
  },
  reducers: {},
  extraReducers: {
    [getStyleContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getStyleContent.fulfilled]: (state, action) => {
      state.styles = action.payload.data;
      state.isLoading = false;
    },
    [getStyleContent.rejected]: (state) => {
      state.isLoading = false;
    },
    [addNewStyleAsync.fulfilled]: (state, action) => {
      // console.log("DUCK", "add New Word", action.payload);
      state.styles = [...state.styles, action.payload.data];
      state.isLoading = false;
    },
    [deleteStyleAsync.fulfilled]: (state, action) => {
      const { index } = action.payload;
      state.styles.splice(index, 1);
    },
    [editStyleAsync.fulfilled]: (state, action) => {
      const {data} = action.payload;
      const index = state.styles.findIndex(
        (item) => item._id === data._id
      );

      if (index !== -1) {
        state.styles[index] = data;
      }
    },
  },
});

export default styleSlice.reducer;
