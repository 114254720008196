import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAvatarContent = createAsyncThunk(
  "/avatars/content",
  async () => {
    const response = await axios.get("/api/easyart/get-avatars/false", {
      headers: {
        Origin: "http://localhost:3000", //Todo:Fix before deploy
      },
    });
    console.log("DUCK", "get-avatar", response.data);
    return response.data;
  }
);

export const addNewAvatarAsync = createAsyncThunk(
  "avatars/addNewAvatarAsync",
  async (newAvatarObj) => {
    console.log("DUCK", "addNewAvatarAsync", newAvatarObj);

    // Create a new FormData instance
    const formData = new FormData();

    // Append the text data to the form data
    formData.append("avatar_name", newAvatarObj.avatar_name);
    formData.append("avatar_prompt", newAvatarObj.avatar_prompt);
    formData.append("avatar_negative_prompt", newAvatarObj.avatar_negative_prompt);
    formData.append("serial_number", newAvatarObj.avatar_serial_number);
    formData.append("avatar_sampler", newAvatarObj.avatar_sampler);
    formData.append("avatar_sampling_step", newAvatarObj.avatar_sampling_step);
    formData.append("avatar_height", newAvatarObj.avatar_height);
    formData.append("avatar_width", newAvatarObj.avatar_width);
    formData.append("avatar_cfg", newAvatarObj.avatar_cfg);
    formData.append("avatar_seed", newAvatarObj.avatar_seed);

    // Append the image file to the form data
    formData.append("avatar_image", newAvatarObj.avatar_image);

    // Make the POST request using Axios
    const response = await axios.post("/api/easyart/add-avatar", formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Set appropriate headers for FormData
      },
    });

    return response.data; // You can modify this as needed based on your API response
  }
);

export const deleteAvatarAsync = createAsyncThunk(
  "avatars/deleteAvatarAsync",
  async (obj) => {
    try {
      const response = await axios.post("/api/easyart/delete-avatar", {
        id: obj._id,
      });

      return obj;
    } catch (error) {
      throw error;
    }
  }
);

export const editAvatarAsync = createAsyncThunk(
  "avatars/editAvatarAsync",
  async (editedAvatar) => {
    const formData = new FormData();

    console.log("DUCK", "editedAvatar", editedAvatar);

    formData.append("id", editedAvatar.avatar_id);
    formData.append("avatar_name", editedAvatar.avatar_name);
    formData.append("avatar_prompt", editedAvatar.avatar_prompt);
    formData.append("avatar_negative_prompt", editedAvatar.avatar_negative_prompt);
    formData.append("serial_number", editedAvatar.avatar_serial_number);
    formData.append("avatar_sampler", editedAvatar.avatar_sampler);
    formData.append("avatar_sampling_step", editedAvatar.avatar_sampling_step);
    formData.append("avatar_height", editedAvatar.avatar_height);
    formData.append("avatar_width", editedAvatar.avatar_width);
    formData.append("avatar_cfg", editedAvatar.avatar_cfg);
    formData.append("avatar_seed", editedAvatar.avatar_seed);
    formData.append("avatar_image", editedAvatar.avatar_image);

    try {
      const response = await axios.post(
        "/api/easyart/edit-avatar",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const avatarSlice = createSlice({
  name: "avatars",
  initialState: {
    isLoading: false,
    avatars: [],
  },
  reducers: {
    // addNewAvatar: (state, action) => {
    //   let { newAvatarObj } = action.payload;

    //   //state.avatars = [...state.avatars, newAvatarObj];
    // },

    // deleteAvatar: (state, action) => {
    //   let { index } = action.payload;
    //   state.avatars.splice(index, 1);
    // },
  },

  extraReducers: {
    [getAvatarContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getAvatarContent.fulfilled]: (state, action) => {
      state.avatars = action.payload.data;
      state.isLoading = false;
    },
    [getAvatarContent.rejected]: (state) => {
      state.isLoading = false;
    },
    [addNewAvatarAsync.fulfilled]: (state, action) => {
      console.log("DUCK", "addNewAvatarAsync", action.payload);
      state.avatars = [...state.avatars, action.payload.data];

      state.isLoading = false;
    },
    [deleteAvatarAsync.fulfilled]: (state, action) => {
      const { index } = action.payload;
      state.avatars.splice(index, 1);
    },
    [editAvatarAsync.fulfilled]: (state, action) => {
      console.log(action.payload.data);
      const editedAvatar = action.payload.data;
      const index = state.avatars.findIndex(
        (item) => item._id === editedAvatar._id
      );

      if (index !== -1) {
        state.avatars[index] = editedAvatar;
      }
    },
  },
});

export const { addNewAvatar, deleteAvatar } =
  avatarSlice.actions;

export default avatarSlice.reducer;
